<template>
  <div id="app">
    <div class="root">
      <img class="bg" :src="`https://img.youtube.com/vi/${videoId}/hqdefault.jpg`" />
      <div class="mask" :style="`background:${mainColor}`" />
      <div class="header">
        <span class="song-name c_text2 c_color1">{{track}}</span>
        <span class="singer-name c_text3 c_color2">{{artist}}</span>
      </div>
      <home :videoId="videoId" :mainColor="mainColor" :lyricsList="lyricsList" style="height:0;flex:1"></home>
      <div class="footer">
        <div class="icon">
          <img style="width:100%;height:100%" src="./assets/google-play.png" />
        </div>
        <span class="c_text4 c_color1">Free Music Downloader</span>
      </div>
    </div>
  </div>
</template>

<script>
import Home from './components/Home.vue'
const axios = require('axios').default
export default {
  name: 'App',
  components: {
    Home
  },
  computed: {},
  data() {
    return {
      videoId: '',
      mainColor: '#000000',
      track: '...',
      artist: '...',
      // bgColor: 'rgba(47,45,102)',
      //0:主页  1:歌词页  2:视频页
      mode: 0,
      lyricsList: []
    }
  },
  created() {
    // this.videoId = 'nDntrhec3GY'
    this.videoId = this.getQueryVariable('id')
    axios
      .get(`https://pcdn.apppmate.com/share/lyrics/${this.videoId}.lrc`)
      .then(response => {
        let data = response.data
        this.mainColor = data.color
        this.track = data.track
        this.artist = data.artist
        this.lyricsList = data.lyric.split(/\r?\n/)
      })
      .catch(err => {
        console.log('err', err)
      })
  },
  methods: {
    getQueryVariable(variable) {
      var query = window.location.search.substring(1)
      var vars = query.split('&')
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=')
        if (pair[0] == variable) {
          return pair[1]
        }
      }
      return false
    }
  }
}
</script>

<style lang="less">
.root {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  .bg {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -100;
    filter: blur(30px);
    object-fit: cover;
  }
  .mask {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -99;
    // background: rgba(47, 45, 102);
    opacity: 0.8;
  }
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 20px;

    .song-name {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-weight: 500;
    }
    .singer-name {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-weight: 400;
      margin-top: 2px;
    }
  }
  .footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
    margin-top: 10px;
    opacity: 0.8;
    .icon {
      display: flex;
      width: 14px;
      height: 14px;
      margin-right: 10px;
    }
  }
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
